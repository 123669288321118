/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import Accordion from '../common/Accordion';

const aState = ['Lesiones pre o post ulcerosas completamente epitelizadas',
  'Herida superficial, no involucra tendón, cápsula o hueso',
  'Herida tendón o cápsula',
  'Herida penetrante a hueso o articulación'];

interface TexasProps {
  texas: string;
  setTexas: React.Dispatch<React.SetStateAction<string>>;
}

const TexasForm = (props:TexasProps):JSX.Element => {
  const { texas, setTexas } = props;
  const handleClick = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setTexas(e.currentTarget.dataset.option || '');
  };
  const getClassName = (key:string) => {
    if (key === texas) {
      return 'option__selected';
    }
    return 'option';
  };

  return (
    <>
      <Accordion title="Clasificación Universidad de Texas:">
        <p>Haga click en la opción que desee seleccionar</p>
        <div className="texas-grid">
          <div className="one">
            ESTADIO
          </div>
          <div className="two">
            GRADO
          </div>
          <div className="grade">0</div>
          <div className="grade">I</div>
          <div className="grade">II</div>
          <div className="grade">III</div>
          <div>A</div>
          {aState.map((state, index) => {
            const key = `A${index}`;
            return (
              <div
                key={key}
                data-option={key}
                className={getClassName(key)}
                onClick={handleClick}
              >
                {state}
              </div>
            );
          })}
          <div>B</div>
          {[1, 2, 3, 4].map((_, index) => {
            const key = `B${index}`;
            return (
              <div
                key={key}
                data-option={key}
                className={getClassName(key)}
                onClick={handleClick}
              >
                Infectada
              </div>
            );
          })}
          <div>C</div>
          {[1, 2, 3, 4].map((_, index) => {
            const key = `C${index}`;
            return (
              <div
                key={key}
                data-option={key}
                className={getClassName(key)}
                onClick={handleClick}
              >
                Isquémica
              </div>
            );
          })}
          <div>D</div>
          {[1, 2, 3, 4].map((_, index) => {
            const key = `D${index}`;
            return (
              <div
                key={key}
                data-option={key}
                className={getClassName(key)}
                onClick={handleClick}
              >
                Infectada + Isquémica
              </div>
            );
          })}
        </div>
      </Accordion>

    </>
  );
};

export default TexasForm;
