import React from 'react';
import PasswordRecoveryForm from './passwordRecoveryForm';
import '../login/login.css';

const PassWordRecover = ():JSX.Element => (
  <div className="login-container">
    <PasswordRecoveryForm />
  </div>
);

export default PassWordRecover;
