import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const rows = [
  'No open lesions; may have deformity or sellulitis',
  'Superficial diabetic ulcer (partial of full thickness)',
  'Ulcer extension to ligament, tendon, joint capsule, or deep fascia whithout abscess or osteomyelitis',
  'Deep ulcer with abscess, osteomyelitis, or joint sepsis',
  'Gangrene localized to portion of forefoot or heel',
  'Extensive gangrenous involvement of the entire foot',
];

const WagnerTable = ():JSX.Element => {
  return (
    <TableContainer component={Paper}>
      <p>Wagner Ulcer Classification System</p>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">GRADE</TableCell>
            <TableCell>LESION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {index}
              </TableCell>
              <TableCell>{row}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WagnerTable;
