import React, { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { tableEntry, modelEntry } from '../../common/data-types';

type Stats = typeof basicStats;
const basicStats = {
  correctas: 0,
  incorrectas: 0,
  realW0: 0,
  realW1: 0,
  realW2: 0,
  realW3: 0,
  realW4W5: 0,
  realHealthy: 0,
  modelW0: 0,
  modelW1: 0,
  modelW2: 0,
  modelW3: 0,
  modelW4W5: 0,
  modelHealthy: 0,
};

type Metrics = typeof basicMetrics;
const basicMetrics = {
  acc: 0,
  accW0: 0,
  accW1: 0,
  accW2: 0,
  accW3: 0,
  accW4W5: 0,
  accHealthy: 0,
};

type DataProps = {
  tableData: Array<tableEntry>;
};

const getPredictedValue = (modelPredictions: modelEntry) => {
  let predicted = '';
  let confidence = 0;
  Object.entries(modelPredictions).forEach(([key, value]) => {
    if (value > confidence) {
      if (key) {
        predicted = key.toString().toUpperCase();
      }
      confidence = value;
    }
  });
  if (predicted === 'W4ORW5') {
    predicted = 'W4 o W5';
  }
  return { predicted, confidence };
};

const computeStatistics = (data: Array<tableEntry>) => {
  const newStats = JSON.parse(JSON.stringify(basicStats));

  data.forEach((entry: tableEntry) => {
    const { predicted } = getPredictedValue(entry.modelClassification);
    const isCorrect = Array.from(predicted.toString()).includes(
      entry.wagnerClassification.toString(),
    );
    if (isCorrect) {
      newStats.correctas += 1;
    }
    if (entry.wagnerClassification === 0) {
      newStats.realW0 += 1;
      if (isCorrect) {
        newStats.modelW0 += 1;
      }
    } else if (entry.wagnerClassification === 1) {
      newStats.realW1 += 1;
      if (isCorrect) {
        newStats.modelW1 += 1;
      }
    } else if (entry.wagnerClassification === 2) {
      newStats.realW2 += 1;
      if (isCorrect) {
        newStats.modelW2 += 1;
      }
    } else if (entry.wagnerClassification === 3) {
      newStats.realW3 += 1;
      if (isCorrect) {
        newStats.modelW3 += 1;
      }
    } else if (entry.wagnerClassification === 4) {
      newStats.realW4W5 += 1;
      if (isCorrect) {
        newStats.modelW4W5 += 1;
      }
    } else if (entry.wagnerClassification === 5) {
      newStats.realW4W5 += 1;
      if (isCorrect) {
        newStats.modelW4W5 += 1;
      }
    }
  });
  newStats.incorrectas += data.length - newStats.correctas;

  return newStats;
};

const computeMetrics = (stats: Stats) => {
  const newMetrics = JSON.parse(JSON.stringify(basicMetrics));
  newMetrics.acc = Math.round((stats.correctas * 100) / (stats.correctas + stats.incorrectas));
  newMetrics.accW0 = Math.round((stats.modelW0 * 100) / stats.realW0);
  newMetrics.accW1 = Math.round((stats.modelW1 * 100) / stats.realW1);
  newMetrics.accW2 = Math.round((stats.modelW2 * 100) / stats.realW2);
  newMetrics.accW3 = Math.round((stats.modelW3 * 100) / stats.realW3);
  newMetrics.accW4W5 = Math.round((stats.modelW4W5 * 100) / stats.realW4W5);
  return newMetrics;
};

const MetricsTable: React.FC<DataProps> = (props) => {
  const { tableData } = props;
  const [displayData, setDisplayData] = useState<Array<tableEntry>>([]);
  const [currentStats, setCurrentStats] = useState<Stats>(basicStats);
  const [currentMetrics, setCurrentMetrics] = useState<Metrics>(basicMetrics);

  useEffect(() => {
    setDisplayData(tableData);
    const thisStats = computeStatistics(tableData);
    const thisMetrics = computeMetrics(thisStats);
    setCurrentStats(thisStats);
    setCurrentMetrics(thisMetrics);
  }, []);

  return (
    <>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <strong>#Muestras</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Total W0</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Total W1</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Total W2</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Total W3</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Total W4 o W5</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Total Sanos</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell align="center">
                <strong>Reales</strong>
              </TableCell>
              <TableCell align="center">{displayData.length}</TableCell>
              <TableCell align="center">{currentStats.realW0}</TableCell>
              <TableCell align="center">{currentStats.realW1}</TableCell>
              <TableCell align="center">{currentStats.realW2}</TableCell>
              <TableCell align="center">{currentStats.realW3}</TableCell>
              <TableCell align="center">{currentStats.realW4W5}</TableCell>
              <TableCell align="center">{currentStats.realHealthy}</TableCell>
            </TableRow>

            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell align="center">
                <strong>Modelo</strong>
              </TableCell>
              <TableCell align="center">{currentStats.correctas}</TableCell>
              <TableCell align="center">{currentStats.modelW0}</TableCell>
              <TableCell align="center">{currentStats.modelW1}</TableCell>
              <TableCell align="center">{currentStats.modelW2}</TableCell>
              <TableCell align="center">{currentStats.modelW3}</TableCell>
              <TableCell align="center">{currentStats.modelW4W5}</TableCell>
              <TableCell align="center">{currentStats.modelHealthy}</TableCell>
            </TableRow>

            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell align="center">
                <strong>Accuracy</strong>
              </TableCell>
              <TableCell align="center">{`${currentMetrics.acc}%`}</TableCell>
              <TableCell align="center"> {`${currentMetrics.accW0}%`} </TableCell>
              <TableCell align="center"> {`${currentMetrics.accW1}%`} </TableCell>
              <TableCell align="center"> {`${currentMetrics.accW2}%`} </TableCell>
              <TableCell align="center"> {`${currentMetrics.accW3}%`} </TableCell>
              <TableCell align="center"> {`${currentMetrics.accW4W5}%`} </TableCell>
              <TableCell align="center"> {`${currentMetrics.accHealthy}%`} </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MetricsTable;
