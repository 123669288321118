import React, { useState } from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { poolData } from '../../common/cognito';
import logo from '../../assets/logo-glik.png';
import RadioQuestion from '../common/RadioQuestion';
import {
  rutParser, rutDeparser, validRut, validDNI,
} from '../../common/idParser';
import '../login/login.css';

type typeRegisterErrors = {
  [key: string]: string;
};

const registerErrors: typeRegisterErrors = {
  UserLambdaValidationException:
    'Contáctese con Michel para que añada su mail a nuestros registros.',
  InvalidRut: 'Rut Inválido',
  UsernameExistsException: 'El usario con este mail ya existe',
};

const RegisterForm = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [id, setId] = useState('');
  const [idMode, setIdMode] = useState(0);
  const [warning, setWarning] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const idHandler = (rutHandler: string) => setId(rutParser(rutHandler));
  const validationHandler = +idMode ? validRut : validDNI;
  const [requirements, setRequirements] = useState(false);
  const register = () => {
    setLoading(true);
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const attributeList: AmazonCognitoIdentity.CognitoUserAttribute[] = [];

    const dataRut = {
      Name: 'custom:rut',
      Value: rutDeparser(id),
    };

    const attributeRut = new AmazonCognitoIdentity.CognitoUserAttribute(dataRut);

    attributeList.push(attributeRut);

    userPool.signUp(email, password, attributeList, [], (err, result) => {
      setLoading(false);
      if (err) {
        setError(err.name);
        return;
      }
      if (result) {
        setWarning(true);
        setEmail('');
        setPassword('');
        setId('');
      }
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (validationHandler(id)) {
      setError('InvalidId');
    } else {
      register();
    }
  };
  return (
    <div className="login-container">
      <h1 style={{ color: 'white' }}>Registrate en la fototeca de Glik</h1>
      <img src={logo} alt="Logo" width="200" style={{ margin: '0 auto' }} />
      <Container
        maxWidth="sm"
        style={{ marginTop: '2em' }}
        sx={{
          borderRadius: '1em',
          bgcolor: 'white',
          padding: '1em 1em 1em 1em',
          textAlign: 'center',
        }}
      >
        {warning && (
          <Alert
            onClose={() => {
              setWarning(false);
            }}
            severity="info"
          >
            Ha sido registrado exitosamente, ahora puede iniciar sesión.
          </Alert>
        )}
        {error && (
          <Alert
            onClose={() => {
              setError('');
            }}
            severity="error"
          >
            {registerErrors[error] || error}
          </Alert>
        )}
        <Button
          disabled={requirements}
          variant="outlined"
          onClick={() => {
            setRequirements(true);
          }}
        >Revisar requisitos de contraseña
        </Button>
        <Collapse in={requirements}>
          <Alert
            severity="info"
            action={(
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setRequirements(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
          )}
          >
            Asegúrese que la contraseña cumpla con los siguientes requisitos.
            <p>Mínimo 6 carácteres.</p>
            <p>Que incluya al menos un número.</p>
            <p>Que incluya al menos una letra mayúscula.</p>
            <p>Que incluya al menos un carácter especial.</p>
          </Alert>
        </Collapse>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="small"
            margin="dense"
          />
          <TextField
            label="Contraseña"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            size="small"
            margin="dense"
          />
          <RadioQuestion
            title="Identificación"
            options={['RUT', 'DNI']}
            onChange={(value: number) => {
              setIdMode(value);
            }}
            required
            value={idMode}
            labelStyle={{ paddingTop: '0.6em', marginRight: '0.5em' }}
            row
          />
          <TextField
            label={Number(idMode) ? 'DNI' : 'RUT'}
            type="text"
            required
            value={id}
            onChange={(e) => idHandler(e.target.value)}
            size="small"
            margin="dense"
          />
          <div>
            <LoadingButton type="submit" variant="contained" color="primary" loading={loading}>
              Registrarse
            </LoadingButton>
          </div>
        </form>
        <Link sx={{ marginTop: '2em' }} href="/">
          Volver al inicio
        </Link>
      </Container>
    </div>
  );
};

export default RegisterForm;
