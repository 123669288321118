export const calculateDV = (rut:number):string => {
  const body = `${rut}`;
  let sum = 0;
  let multiplier = 2;

  for (let i = 1; i <= body.length; i += 1) {
    const index = multiplier * Number(body.charAt(body.length - i));

    sum += index;

    if (multiplier < 7) {
      multiplier += 1;
    } else {
      multiplier = 2;
    }
  }
  const dvExpected = 11 - (sum % 11);
  if (dvExpected === 10) return 'k';
  if (dvExpected === 11) return '0';
  return `${dvExpected}`;
};

export const rutParser = (rut:string):string => {
  const parsedRut = rut.replace('.', '').replace('.', '').replace('-', '');
  if (parsedRut.length === 0 || parsedRut === '-') {
    return '';
  }
  if (parsedRut.length < 4 && parsedRut.length > 0) {
    return parsedRut.slice(0, -1).concat('-', parsedRut.substr(-1, 1));
  }
  if (parsedRut.length >= 4 && parsedRut.length < 7) {
    return parsedRut
      .slice(0, -4)
      .concat('.', parsedRut.slice(-4, -1), '-', parsedRut.substr(-1, 1));
  }
  return parsedRut
    .slice(0, -7)
    .concat(
      '.',
      parsedRut.slice(-7, -4),
      '.',
      parsedRut.slice(-4, -1),
      '-',
      parsedRut.substr(-1, 1),
    );
};

export const validRut = (rut:string):boolean => {
  if (!rut || rut.trim().length < 3) return false;
  const cleanRut = rut.replace(/[^0-9kK-]/g, '');
  if (cleanRut.length < 3) return false;

  const split = cleanRut.split('-');
  if (split.length !== 2) return false;

  const num = parseInt(split[0], 10);
  const dgv = split[1];

  const dvCalc = calculateDV(num);
  return dvCalc.toLocaleLowerCase() === dgv.toLocaleLowerCase();
};

export const rutDeparser = (rut: string):string => {
  const parsedRut = rut.split('-');
  const [main, dv] = parsedRut;
  return `${main.replace(/\./gi, '')}${dv}`;
};

export const removeAccents = (text:string):string => text.normalize('NFD').replace(/\p{Diacritic}/gu, '');

export const validDNI = (dni:string):boolean => {
  const [num, dv] = dni.replace(/[^0-9kK-]/g, '').split('-');
  const constants = [2, 9, 8, 7, 6, 3, 4];
  let sum = 0;
  for (let i = 0; i < num.length; i += 1) {
    sum += +(num[i]) * constants[i];
  }
  const m = sum % 10;
  const h = (10 - m) % 10;
  return +dv === h;
};
