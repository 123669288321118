import React from 'react';
import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/material/styles';

interface FileUpload {
  onFileChange: React.Dispatch<React.SetStateAction<FileList|undefined>>;
}

const fileUpload = (props:FileUpload):JSX.Element => {
  const { onFileChange } = props;
  const Input = styled('input')({
    display: 'none',
  });
  const buttonStyle = { bgcolor: '#08979d', fontWeight: 'bold', marginTop: '1em' };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target as HTMLInputElement;
    if (files) {
      onFileChange(files);
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor="contained-button-file">
      <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={handleChange} />
      <Button variant="contained" component="span" endIcon={<PhotoCamera />} sx={buttonStyle}>
        Subir Fotos
      </Button>
    </label>
  );
};

export default fileUpload;
