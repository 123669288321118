import React, { useState, useContext } from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import QR from 'qrcode';
import Link from '@mui/material/Link';
import { UserContext } from '../userContext';
import { poolData, onSuccesCallback } from '../../common/cognito';

type typeloginErrors = {
  [key: string]: string;
};

const loginErrors: typeloginErrors = {
  NotAuthorizedException: 'Contraseña inválida',
  EnableSoftwareTokenMFAException: 'El código ingresado es incorrecto',
  UserNotFoundException: 'EL correo ingresado es incorrecto.',
};
const LoginForm: React.FC<Record<string, never>> = () => {
  const history = useHistory();
  const { setLoginStatus } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [qRCode, setQRCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState({ src: '', renderImage: false });
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const [renderMfa, setRenderMfa] = useState(false);
  const [keyMfa, setKeyMfa] = useState('');

  const [cognitoUser, setCognitoUser] = useState(
    new AmazonCognitoIdentity.CognitoUser({ Username: email, Pool: userPool }),
  );

  const authenticationData = {
    Username: email,
    Password: password,
  };
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const newCognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });

    setCognitoUser(newCognitoUser);
    newCognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        onSuccesCallback(result, setLoginStatus);
      },
      onFailure: (err) => {
        const { code } = err;
        setError(loginErrors[code]);
      },
      totpRequired() {
        // eslint-disable-next-line no-alert
        setRenderMfa(true);
      },
      async mfaSetup() {
        newCognitoUser.associateSoftwareToken({
          async associateSecretCode(secretCode) {
            const name = 'Glik-Fototeca';
            const uri = `otpauth://totp/${decodeURI(name)}?secret=${secretCode}`;
            const qr = (await QR.toDataURL(uri).then((url: string) => url)) || '';
            setImageData({ src: qr, renderImage: true });
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onFailure: () => {},
        });
      },
    });
    setLoading(false);
  };

  const verifyMFA = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const challengeAnswer = qRCode;
    cognitoUser.verifySoftwareToken(challengeAnswer, 'My TOTP device', {
      onSuccess: (result) => {
        onSuccesCallback(result, setLoginStatus);
        setLoading(false);
        history.push('/');
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onFailure: (err) => {
        setLoading(false);
        setError(loginErrors[err.name]);
      },
    });
  };

  const signInMFA = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    cognitoUser.sendMFACode(
      keyMfa,
      {
        onSuccess: (result) => {
          onSuccesCallback(result, setLoginStatus);
          setLoading(false);
          history.push('/');
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onFailure: () => {
          setLoading(false);
        },
      },
      'SOFTWARE_TOKEN_MFA',
    );
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        borderRadius: '1em',
        bgcolor: 'white',
        padding: '1em 1em 1em 1em',
        textAlign: 'center',
      }}
    >
      {error && (
        <Alert
          onClose={() => {
            setError('');
          }}
          severity="error"
        >
          {error}
        </Alert>
      )}
      {!imageData.renderImage && !renderMfa && (
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="small"
            margin="dense"
          />
          <TextField
            label="Contraseña"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            size="small"
            margin="dense"
          />
          <div>
            <LoadingButton type="submit" variant="contained" color="primary" loading={loading}>
              Iniciar Sesión
            </LoadingButton>
          </div>
        </form>
      )}
      {imageData.renderImage && (
        <div>
          <p>
            Escanee el siguiente código QR con la apliación de GoogleAuth para obtener su código de
            verificación.
          </p>
          <img alt="qrcode" src={imageData.src} />
          <form onSubmit={verifyMFA}>
            <TextField
              label="Código de Verificación"
              type="text"
              required
              value={qRCode}
              onChange={(e) => setQRCode(e.target.value)}
              size="small"
              margin="dense"
            />
            <div>
              <LoadingButton type="submit" variant="contained" color="primary" loading={loading}>
                ENVIAR CÓDIGO
              </LoadingButton>
            </div>
          </form>
        </div>
      )}
      {renderMfa && (
        <div>
          <form onSubmit={signInMFA}>
            <TextField
              label="Clave de segundo factor"
              type="text"
              required
              value={keyMfa}
              onChange={(e) => setKeyMfa(e.target.value)}
              size="small"
              margin="dense"
            />
            <div>
              <LoadingButton type="submit" variant="contained" color="primary" loading={loading}>
                ENVIAR CÓDIGO
              </LoadingButton>
            </div>
          </form>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p>Si no tienes cuenta:</p>
        <Link sx={{ marginTop: '1em', marginLeft: '0.5em' }} href="/registro">
          Registrate aquí
        </Link>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p>¿Olvidaste tu contraseña?:</p>
        <Link sx={{ marginTop: '1em', marginLeft: '0.5em' }} href="/password_recover">
          Solicita tu código
        </Link>
      </div>
    </Container>
  );
};

export default LoginForm;
