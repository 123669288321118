import React from 'react';

import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { userPool } from '../common/cognito';
import GlikLogo from '../assets/logo-glik.png';
import { AccountContext } from '../common/Account';

const pages = ['Resultados', 'WhiteList'];
const pagesRoutes = ['/resultados', '/new-user'];
const settings = ['Reestablecer Contraseña', 'Cerrar Sesión'];

const NavBar = (): JSX.Element => {
  const history = useHistory();
  const currentUser = userPool.getCurrentUser();
  const { getSession } = React.useContext(AccountContext);
  const [showAdminPages, setShowAdminPages] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [email, setEmail] = React.useState('');
  const settingsAction = (action: string) => {
    if (!currentUser) return;
    if (action === 'Reestablecer Contraseña') {
      history.push('/contraseña');
      return;
    }
    currentUser.signOut();
    history.push('/login');
  };
  const checkAdminGroup = async () => {
    const { session, attr } = await getSession();
    setEmail(attr.email);
    if (session) {
      const groups = session.getIdToken().decodePayload()['cognito:groups'];
      if (groups) {
        setShowAdminPages(true);
      }
    }
  };
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    checkAdminGroup();
  });
  return (
    <AppBar position="static" sx={{ bgcolor: '#08979d' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Button
            onClick={() => history.push('/')}
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Box
              sx={{
                height: 50,
                width: 125,
                maxHeight: { md: 30 },
                maxWidth: { md: 75 },
              }}
              component="img"
              alt="Logo Glik"
              src={GlikLogo}
            />
          </Button>

          {showAdminPages && (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {['Inicio', ...pages].map((page, index) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center" onClick={() => history.push(['/', ...pagesRoutes][index])}>{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page, index) => (
                  <Button
                    key={page}
                    onClick={() => {
                      handleCloseNavMenu();
                      history.push(pagesRoutes[index]);
                    }}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
            </>
          )}

          {currentUser && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Configuración">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={email.toLocaleUpperCase()} src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => settingsAction(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
