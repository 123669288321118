/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Container from '@mui/material/Container';
import { UserContext } from '../../components/userContext';
import NavBar from '../../components/Navbar';
import { userPool } from '../../common/cognito';

interface Props extends RouteProps {
  component: React.FC;
}
const ProtectedRoute = (props: Props): JSX.Element => {
  const { component: Component, ...rest } = props;
  const { succesfullLogin } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(renderProps) => {
        if (userPool.getCurrentUser() || succesfullLogin) {
          return (
            <div>
              <NavBar />
              <Container
                maxWidth="lg"
                sx={{
                  marginTop: '1em',
                  marginBottom: '1em',
                  borderRadius: '1em',
                  bgcolor: 'white',
                  textAlign: 'center',
                }}
              >
                <Component />
              </Container>
            </div>
          );
        }
        return <Redirect to={{ pathname: '/login', state: { from: renderProps.location } }} />;
      }}
    />
  );
};

export default ProtectedRoute;
