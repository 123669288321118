import React, { useState } from 'react';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { userPool } from '../common/cognito';

const ForgotPasswordForm = (): JSX.Element => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const currentUser = userPool.getCurrentUser();
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    if (currentUser) {
      currentUser.getSession(() => {
        if (currentUser) {
          currentUser.changePassword(currentPassword, newPassword, (err) => {
            if (err) {
              setError(true);
            } else {
              setSuccess(true);
              setCurrentPassword('');
              setNewPassword('');
            }
            setLoading(false);
          });
        }
      });
    }
  };
  return (
    <Container
      maxWidth="lg"
      sx={{
        marginTop: '1em',
        marginBottom: '1em',
        borderRadius: '1em',
        bgcolor: 'white',
        textAlign: 'center',
      }}
    >
      <h1>Reestablecer Contraseña</h1>
      {error && (
        <Alert
          onClose={() => {
            setError(false);
          }}
          severity="error"
        >
          Ha ocurrido un error, cuelva a intentar esta acción más tarde.
        </Alert>
      )}
      {success && (
        <Alert
          onClose={() => {
            setSuccess(false);
          }}
          severity="success"
        >
          ¡Se ha reestablecido la contraseña!
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Contraseña actual"
          type="password"
          required
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          size="small"
          margin="dense"
        />
        <TextField
          label="Nueva Contraseña"
          type="password"
          required
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          size="small"
          margin="dense"
        />
        <LoadingButton type="submit" variant="contained" color="primary" loading={loading}>
          Reestablecer Contraseña
        </LoadingButton>
      </form>
    </Container>
  );
};

export default ForgotPasswordForm;
