import React, { useState, useContext } from 'react';

import { Grid, Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { AccountContext } from '../../common/Account';
import { apiAddNewUser } from '../../api/admin';

const WhiteList = (): JSX.Element => {
  const [isLoading, setLoading] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [message, setMessage] = useState('');
  const { getSession } = useContext(AccountContext);

  const handleSendRequest = async () => {
    setLoading(true);

    const { session } = await getSession();
    if (session) {
      const token = session.getAccessToken().getJwtToken();
      const response = await apiAddNewUser(newEmail, token);
      setMessage(response.message);
      if (response.status === 'success') {
        setNewEmail('');
      }
    }
    setLoading(false);
  };

  return (
    <div>
      {message !== '' && (
        <Alert
          onClose={() => {
            setMessage('');
          }}
        >
          {message}
        </Alert>
      )}
      <h2>Agrega un nuevo email a la whitelist!</h2>
      <Grid container alignItems="center" direction="column">
        <Box sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}>
          <TextField
            id="input-with-icon-textfield"
            onChange={(event) => setNewEmail(event.target.value)}
            value={newEmail}
            disabled={isLoading}
            label="Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Box>

        <Box sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}>
          <LoadingButton
            size="small"
            onClick={handleSendRequest}
            endIcon={<SendIcon />}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
          >
            Send
          </LoadingButton>
        </Box>
      </Grid>
    </div>
  );
};

export default WhiteList;
