/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */

import React, { useEffect, useState, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { Container, Stack, TextField, Snackbar, Alert } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import CancelIcom from '@mui/icons-material/Cancel';

import GeneralData from '../../components/Form/generalData';
import WagnerForm from '../../components/Form/WagnerForm';
import SanElianForm from '../../components/Form/SanElianForm';
import TexasForm from '../../components/Form/TexasForm';
import ColorPicker from '../../components/common/colorPicker';

import { metricsEntry, tableEntry } from '../../common/data-types';
import { FormHook, formatDate } from '../../components/Form/formHook';
import { AccountContext } from '../../common/Account';

import { editEntry, deleteEntry } from '../../api/admin';

import '../../components/Form/form.css';

const EditEntry = (): JSX.Element => {
  const location = useLocation();
  const state = location.state as metricsEntry;
  const { getSession } = useContext(AccountContext);

  const [isLoading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [doctorID, setDoctorID] = useState('');
  const [doctorEmail, setDoctorEmail] = useState('');

  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [alreadySent, setAlreadySent] = useState(false);
  const [shouldRedirect, setShouldRedirect] = React.useState(false);

  const {
    generalDataProps,
    sanElianProps,
    texasProps,
    wagner,
    setWagner,
    hasInjury,
    setHasInjury,
    sanElian,
    texasUniversityClassification,
    doctorName,
    setDoctorName,
  } = FormHook();

  const setDoctorData = async () => {
    const { attr, session } = await getSession();
    setDoctorID(attr['custom:rut']);
    setDoctorEmail(attr.email);
    setDoctorName(state.data[0].userName);
    if (session) {
      setToken(session.getAccessToken().getJwtToken());
    }
  };

  const setGeneralProps = async () => {
    generalDataProps.setNames(state.data[0].pacientNames);
    generalDataProps.setLastNames(state.data[0].pacientSurnames);
    generalDataProps.setRut(state.rut);

    const bDateParts = state.data[0].pacientDateOfBirth.split('/');
    const birthDate = new Date(`${bDateParts[2]}/${bDateParts[1]}/${bDateParts[0]}`);

    const rDateParts = state.data[0].dateRegistrationImages.split('/');
    const registerDate = new Date(`${rDateParts[2]}/${rDateParts[1]}/${rDateParts[0]}`);

    generalDataProps.setBirthDate(birthDate);
    generalDataProps.setRegisterDate(registerDate);
    generalDataProps.setSide(state.data[0].side === 'Izquierdo' ? 0 : 1);

    generalDataProps.setSkinColor(state.data[0].skinColor || '');
  };

  const setInjuriesState = async () => {
    const wagnerClass = state.data[0].wagnerClassification;
    setWagner(wagnerClass);
    if (wagnerClass) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const elianClass: any = state.data[0].sanElian;
      sanElianProps.setAffectedZones(elianClass.affectedZones);
      sanElianProps.setArea(elianClass.area);
      sanElianProps.setDepth(elianClass.depth);
      sanElianProps.setEdema(elianClass.edema);
      sanElianProps.setInfection(elianClass.infection);
      sanElianProps.setIschemia(elianClass.ischemia);
      sanElianProps.setLocation(elianClass.location);
      sanElianProps.setNeuropathy(elianClass.neuropathy);
      sanElianProps.setTopographicAspects(elianClass.topographicAspects);
      sanElianProps.setWoundHealingPhase(elianClass.woundHealingPhase);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const texasClass: any = state.data[0].texasUniversityClassification;
    texasProps.setTexas(texasClass.stadium + texasClass.grade);

    // Set hasInjury states
    const injuries: Record<number, boolean> = {};
    state.data.forEach((entry: tableEntry) => {
      injuries[entry.idx] = entry.hasInjury;
    });
    setHasInjury(injuries);
  };

  useEffect(() => {
    setLoading(true);
    setDoctorData();
    setGeneralProps();
    setInjuriesState();
    setLoading(false);
  }, []);

  const updateSubmissionValues = () => {
    const { data } = state;
    data.forEach((image: tableEntry) => {
      image.pacientNames = generalDataProps.names;
      image.pacientSurnames = generalDataProps.lastNames;
      image.pacientRut = generalDataProps.rut;

      const formattedBirthDate = formatDate(generalDataProps.birthDate);
      if (formattedBirthDate) {
        image.pacientDateOfBirth = formattedBirthDate;
      }
      const formattedRegisterDate = formatDate(generalDataProps.registerDate);
      if (formattedRegisterDate) {
        image.dateRegistrationImages = formattedRegisterDate;
      }
      image.age = generalDataProps.age;
      image.side = generalDataProps.side === 0 ? 'Izquierdo' : 'Derecho';
      image.skinColor = generalDataProps.skinColor;
      image.sanElian = sanElian;
      image.texasUniversityClassification = texasUniversityClassification;
      image.wagnerClassification = wagner;
      if (hasInjury[image.idx] == null) {
        image.hasInjury = false;
      }
      image.hasInjury = hasInjury[image.idx];

      image.userName = doctorName;
      image.userRut = doctorID;
      image.doctorEmail = doctorEmail;
    });
    return data;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const updatedData = updateSubmissionValues();
    const response = await editEntry(updatedData, token);
    setMessage(response.message);
    if (response.status === 'failed') {
      setIsError(true);
    } else {
      setAlreadySent(true);
      setIsError(false);
      setTimeout(() => {
        setShouldRedirect(true);
      }, 4000);
    }
    setLoading(false);
  };

  const handleDelete = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const entryKeys: Array<string> = [];
    state.data.forEach((entry) => {
      entryKeys.push(entry.pk);
    });

    const response = await deleteEntry(entryKeys, token);
    setMessage(response.message);
    if (response.status === 'failed') {
      setIsError(true);
    } else {
      setAlreadySent(true);
      setIsError(false);
      setTimeout(() => {
        setShouldRedirect(true);
      }, 4000);
    }
    setLoading(false);
  };

  if (shouldRedirect) return <Redirect to="/resultados" />;

  return (
    <Container
      maxWidth="lg"
      sx={{
        marginTop: '1em',
        marginBottom: '1em',
        borderRadius: '1em',
        bgcolor: 'white',
        textAlign: 'center',
      }}
    >
      <form>
        <GeneralData {...generalDataProps} />
        <ColorPicker skinColor={generalDataProps.skinColor} setSkinColor={generalDataProps.setSkinColor} />
        <WagnerForm wagner={wagner} setWagner={setWagner} />
        {wagner ? <SanElianForm {...sanElianProps} /> : null}
        <TexasForm {...texasProps} />
        <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
          {state.data.map((photoEntry: tableEntry) => {
            return (
              <Stack direction="column" alignItems="center" key={`grid-${photoEntry.pk}`}>
                <FormControlLabel
                  control={
                    <input
                      type="checkbox"
                      checked={hasInjury[photoEntry.idx]}
                      onChange={() => setHasInjury({ ...hasInjury, [photoEntry.idx]: !hasInjury[photoEntry.idx] })}
                    />
                  }
                  label="Imagen responsable de la clasificación?"
                />
                <img
                  src={photoEntry.originalUrl}
                  alt={photoEntry.objectKey}
                  style={{ maxWidth: '100%', maxHeight: 550 }}
                />
              </Stack>
            );
          })}
        </Stack>
        <TextField
          label="Nombre del que registra"
          type="text"
          required
          value={doctorName}
          onChange={(e) => setDoctorName(e.target.value)}
          margin="dense"
        />
        {!alreadySent && (
          <>
            <LoadingButton
              variant="contained"
              endIcon={<SendIcon />}
              color="success"
              sx={{ marginTop: '1em', marginBottom: '1em' }}
              type="submit"
              loading={isLoading}
              disabled={alreadySent}
              onClick={handleSubmit}
            >
              Editar datos del paciente
            </LoadingButton>
            o
            <LoadingButton
              variant="contained"
              startIcon={<CancelIcom />}
              color="error"
              sx={{ marginTop: '1em', marginBottom: '1em' }}
              type="submit"
              loading={isLoading}
              disabled={alreadySent}
              onClick={handleDelete}
            >
              Eliminar entrada de la base de datos
            </LoadingButton>
          </>
        )}
      </form>
      <Snackbar open={message !== ''} autoHideDuration={3000} onClose={() => setMessage('')}>
        <Alert onClose={() => setMessage('')} severity={isError ? 'error' : 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditEntry;
