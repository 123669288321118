/* eslint-disable react/destructuring-assignment */
import React, { useState, createContext } from 'react';

interface UsersProps {
  children: JSX.Element[] | JSX.Element;
}

type UserContextType = {
  succesfullLogin: boolean;
  setLoginStatus: (user: boolean) => void;
}

export const UserContext = createContext<UserContextType>({
  succesfullLogin: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoginStatus: () => {},
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UserProvider = (props: UsersProps) => {
  const [succesfullLogin, setLoginStatus] = useState(false);

  return (
    <UserContext.Provider value={{ succesfullLogin, setLoginStatus }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
