import React from 'react';
// import Container from '@mui/material/Container';
import LoginForm from './loginForm';
import logo from '../../assets/logo-glik.png';
import './login.css';

const LoginPage = ():JSX.Element => (
  <div className="login-container">
    <h1 style={{ color: 'white' }}>Bienvenido a la Fototeca de Glik</h1>
    <img src={logo} alt="Logo" width="200" style={{ margin: '0 auto' }} />
    <LoginForm />
  </div>
);

export default LoginPage;
