/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import Alert from '@mui/material/Alert';
import RadioQuestion from '../common/RadioQuestion';
import { SanElianProps } from '../../common/form-types';
import Accordion from '../common/Accordion';

const locationOptions = ['Falanges', 'Metatarsal', 'Tarsal'];
const topographicOptions = ['Dorsal o plantar', 'Lateral o medial', 'Dos o más'];
const affectedOptions = ['Uno', 'Dos', 'Tres'];
const ischemiaOptions = [
  'Sin Isquemia',
  'Pulsos palpables, levemente disminuidos ó ITB 0,89-0,7 ó IDB 0,74-0,6',
  'Pulsos débiles, poco palbables ó ITB 0,69-0,5 ó IDB 0,59-0,3',
  'Sin pulsos palpables ó ITB <0,5 ó IDB <0,3',
];
const infectionOptions = [
  'Sin Infección',
  'Eritema < 2cm, descarga purulenta, caliente, doloroso',
  'Eritema > 2cm, infección en músculo, tendón, articulación o hueso',
  'SIRS, hiperglicemia o hipoglicemia secundaria',
];
const edemaOptions = [
  'Sin edema',
  '1. Alrededor de la herida',
  'Un pie o una pierna',
  'Bilateral secundaria a comorbilidades',
];
const neuropathyOptions = [
  'Sin Neuropatía',
  'Sensibilidad protectora disminuida',
  'Sensibilidad protectora ausente',
  'Pie de Charcot, Neurosteoartropatía diabética',
];
const areaOptions = ['Pequeña < 10cm^2', 'Mediana 10-40cm^2', 'Grande > 40cm^2'];
const depthOptions = ['Superficial (piel)', 'Tendondes. fascia, músculos', 'Artícular, huesos'];

const woundHealingOptions = ['Epitelización', 'Granulatoria', 'Inflamatoria'];
const grades = ['I', 'II', 'III'];

const SanElianForm = (props: SanElianProps): JSX.Element => {
  const {
    location,
    setLocation,
    topographicAspects,
    setTopographicAspects,
    affectedZones,
    setAffectedZones,
    ischemia,
    setIschemia,
    infection,
    setInfection,
    edema,
    setEdema,
    neuropathy,
    setNeuropathy,
    depth,
    setDepth,
    area,
    setArea,
    woundHealingPhase,
    setWoundHealingPhase,
    sum,
    grade,
  } = props;
  return (
    <>
      <Accordion
        title="Clasificación San Elián"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',

        }}
      >
        <div className="legend">
          <Alert severity="info">
            Suma: {sum}
          </Alert>
          <Alert severity="info">
            Grado: {grades[grade - 1]}
          </Alert>
        </div>
        {/* <div className="sanelian-form"> */}
        <RadioQuestion
          title="Localización de la herida primaria"
          options={locationOptions}
          onChange={setLocation}
          required
          value={location}
          name="Localization"
          startAt={1}
        />
        <RadioQuestion
          title="Localización topográfica"
          value={topographicAspects}
          options={topographicOptions}
          onChange={setTopographicAspects}
          required
          startAt={1}
        />
        <RadioQuestion
          title="Número de zonas afectadas (ver 1)"
          options={affectedOptions}
          onChange={setAffectedZones}
          required
          value={affectedZones}
          startAt={1}
        />
        <RadioQuestion
          title="Isquemia"
          options={ischemiaOptions}
          onChange={setIschemia}
          required
          value={ischemia}
        />
        <RadioQuestion
          title="Infección"
          options={infectionOptions}
          onChange={setInfection}
          value={infection}
          required
        />
        <RadioQuestion
          title="Edema"
          options={edemaOptions}
          onChange={setEdema}
          value={edema}
          required
        />
        <RadioQuestion
          title="Neuropatía"
          options={neuropathyOptions}
          onChange={setNeuropathy}
          value={neuropathy}
          required
        />
        <RadioQuestion
          title="Área"
          options={areaOptions}
          onChange={setArea}
          required
          value={area}
          startAt={1}
        />
        <RadioQuestion
          title="Profundidad"
          options={depthOptions}
          onChange={setDepth}
          required
          value={depth}
          startAt={1}
        />
        <RadioQuestion
          title="Etapa de Cicatrización"
          options={woundHealingOptions}
          onChange={setWoundHealingPhase}
          required
          value={woundHealingPhase}
          startAt={1}
        />
        {/* </div> */}
      </Accordion>

    </>
  );
};

export default SanElianForm;
