import React from 'react';
import RadioQuestion from '../common/RadioQuestion';
import WagnerTable from '../common/wagnerTable';
import Accordion from '../common/Accordion';

interface WagnerProps {
  wagner: number;
  setWagner: React.Dispatch<React.SetStateAction<number>>;
}

const WagnerForm = (props:WagnerProps):JSX.Element => {
  const { wagner, setWagner } = props;
  return (
    <>
      <Accordion title="Clasificación Wagner">
        <RadioQuestion
          title=""
          options={['0', '1', '2', '3', '4', '5']}
          onChange={setWagner}
          required
          formStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}
          row
          name="wagner"
          value={wagner}
        />
        <WagnerTable />
      </Accordion>

    </>
  );
};

export default WagnerForm;
