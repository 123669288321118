import React, {
  useState, useEffect, useContext, useReducer,
} from 'react';

import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { Search, SearchIconWrapper, StyledInputBase } from '../../components/common/searchBar';
import DataTable from './dataTable';
import MetricsTable from './metricsTable';

import { AccountContext } from '../../common/Account';
import { requestResults, defaultResultsResponse } from '../../api/admin';
import { tableEntry, metricsEntry } from '../../common/data-types';

/* eslint-disable no-param-reassign */
const groupDataByBatch = (data: Array<tableEntry>) => {
  const groupedData = data.reduce((previous, currentItem) => {
    const group = currentItem.batch;
    if (!previous[group]) {
      previous[group] = [];
    }
    previous[group].push(currentItem);
    return previous;
  }, Object.create(null));

  const results = Object.keys(groupedData).map((key) => {
    const firstElement = groupedData[key][0];
    return {
      batch: key,
      rut: firstElement.pacientRut,
      age: firstElement.age,
      date: firstElement.dateRegistrationImages,
      user: firstElement.userName,
      data: groupedData[key],
      creationDate: firstElement.createdAt,
    };
  });

  return results.sort((a, b) => (a.creationDate >= b.creationDate ? 1 : -1));
};

const initialState: {rawData: tableEntry[], tableData: metricsEntry[]} = {
  rawData: [],
  tableData: [],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: {rawData: tableEntry[], tableData: metricsEntry[]}, action: {type: string, payload: any}) => {
  switch (action.type) {
    case 'setTableData':
      return {
        ...state, tableData: action.payload,
      };
    case 'addData':
      // eslint-disable-next-line no-case-declarations
      const groupedData = groupDataByBatch(state.rawData.concat(action.payload));
      return {
        rawData: state.rawData.concat(action.payload), tableData: groupedData,
      };
    case 'changeData':
      // eslint-disable-next-line no-case-declarations
      return {
        ...state, tableData: action.payload,
      };
    default:
      throw new Error();
  }
};

const ResultsPage = (): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [displayMetrics, setDisplayMetrics] = useState(false);
  // const [isComplete, setComplete] = useState(false);
  const { getSession } = useContext(AccountContext);
  const { rawData } = state;

  const requestHandler = ({ status, message: msg, data }: defaultResultsResponse) => {
    if (status === 'success') {
      dispatch({ type: 'addData', payload: data.items });
      // setLoading(false);
    } else if (status === 'completed') {
      dispatch({ type: 'addData', payload: data.items });
      setLoading(false);
      // setComplete(true);
    } else {
      setMessage(msg);
    }
  };

  const loadMetricsData = async () => {
    setLoading(true);
    const { session } = await getSession();

    if (session) {
      const token = session.getAccessToken().getJwtToken();
      const limit = 30;
      const lastEvaluatedKey = null;
      requestResults(token, limit, lastEvaluatedKey, requestHandler);
    }
  };

  const searchEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    const filteredData = rawData.filter((row: tableEntry) => {
      const checkBatch = row.batch.toLowerCase().includes(query);
      const checkRut = row.pacientRut.toLowerCase().includes(query);
      const checkName = row.userName.toLowerCase().includes(query);
      return checkBatch || checkRut || checkName;
    });
    const groupedData = groupDataByBatch(filteredData);
    dispatch({ type: 'setTableData', payload: groupedData });
  };

  useEffect(() => {
    loadMetricsData();
  }, []);

  if (isLoading) {
    return <h2>Cargando resultados!</h2>;
  }

  return (
    <div>
      <p>{message}</p>
      {!displayMetrics ? (
        <>
          <Paper sx={{ width: '100%', height: '100%' }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Batch, RUT o Encargado/a"
                inputProps={{ 'aria-label': 'search' }}
                onChange={searchEvent}
              />
            </Search>
            <DataTable state={state} dispatch={dispatch} />
          </Paper>
          <Button onClick={() => setDisplayMetrics(true)}>Ver Métricas</Button>
        </>
      ) : (
        <>
          <Paper sx={{ width: '100%', height: '100%' }}>
            <MetricsTable tableData={rawData} />
          </Paper>
          <Button onClick={() => setDisplayMetrics(false)}>Ver Resultados</Button>
        </>
      )}
    </div>
  );
};

export default ResultsPage;
