/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Container, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormHook from './formHook';
import GeneralData from './generalData';
import WagnerForm from './WagnerForm';
import SanElianForm from './SanElianForm';
import TexasForm from './TexasForm';
import ColorPicker from '../common/colorPicker';
import FileUpload from '../common/fileUploader';
import './form.css';

const MainForm = (): JSX.Element => {
  const {
    generalDataProps,
    sanElianProps,
    texasProps,
    wagner,
    setWagner,
    doctorName,
    setDoctorName,
    doctorID,
    handleSubmit,
    images,
    setImages,
    hasInjury,
    setHasInjury,
    message,
    setMessage,
    uploadError,
    setError,
    loading,
  } = FormHook();
  const { idMode } = generalDataProps;

  return (
    <Container
      maxWidth="lg"
      sx={{
        marginTop: '1em',
        marginBottom: '1em',
        borderRadius: '1em',
        bgcolor: 'white',
        textAlign: 'center',
      }}
    >
      <form onSubmit={handleSubmit}>
        <GeneralData {...generalDataProps} />
        <ColorPicker skinColor={generalDataProps.skinColor} setSkinColor={generalDataProps.setSkinColor} />
        <WagnerForm wagner={wagner} setWagner={setWagner} />
        {wagner ? <SanElianForm {...sanElianProps} /> : null}
        <TexasForm {...texasProps} />

        <TextField
          label="Nombre del que registra"
          type="text"
          required
          value={doctorName}
          onChange={(e) => setDoctorName(e.target.value)}
          margin="dense"
        />
        <TextField
          id="outlined-read-only-input"
          label={Number(idMode) ? 'DNI' : 'RUT'}
          disabled
          value={doctorID}
          InputProps={{
            readOnly: true,
          }}
          margin="dense"
        />
        <FileUpload
          onFileChange={(event) => {
            setImages(event);
            if (event) {
              const injuriesVector: Record<string, boolean> = {};
              for (let idx = 0; idx < event.length; idx += 1) {
                injuriesVector[idx] = false;
              }
              setHasInjury(injuriesVector);
            }
          }}
        />
        {images && (
          <>
            <ListItem>
              <ListItemText primary="Archivos seleccionados:" />
            </ListItem>
            <Stack direction="row" spacing={2} style={{ overflow: 'auto' }}>
              {Array.from(images).map((image: File, idx: number) => (
                <Stack key={image.name} direction="column" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasInjury[idx]}
                        onChange={() => setHasInjury({ ...hasInjury, [idx]: !hasInjury[idx] })}
                      />
                    }
                    label="Imagen responsable de la clasificación?"
                  />
                  <img src={URL.createObjectURL(image)} alt={image.name} style={{ width: '500px' }} />
                  <strong>{image.name}</strong>
                </Stack>
              ))}
            </Stack>
          </>
        )}
        <LoadingButton
          variant="contained"
          endIcon={<SendIcon />}
          color="success"
          sx={{ marginTop: '1em' }}
          type="submit"
          loading={loading}
        >
          Enviar
        </LoadingButton>
      </form>
      <Snackbar open={message} autoHideDuration={3000} onClose={() => setMessage(false)}>
        <Alert onClose={() => setMessage(false)} severity="success" sx={{ width: '100%' }}>
          Registro Existoso!
        </Alert>
      </Snackbar>
      <Snackbar open={uploadError !== undefined} autoHideDuration={5000} onClose={() => setError(undefined)}>
        <Alert onClose={() => setError(undefined)} severity="error" sx={{ width: '100%' }}>
          {`${uploadError}`}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MainForm;
