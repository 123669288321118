/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { Stack, Divider } from '@mui/material';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import es from 'date-fns/locale/es';
import Typography from '@mui/material/Typography';
import Accordion from '../common/Accordion';
import { GeneralDataProps } from '../../common/form-types';
import { rutParser } from '../../common/idParser';
import RadioQuestion from '../common/RadioQuestion';

const GeneralData = (props: GeneralDataProps): JSX.Element => {
  const {
    names,
    setNames,
    lastNames,
    setLastNames,
    rut,
    setRut,
    birthDate,
    setBirthDate,
    registerDate,
    setRegisterDate,
    side,
    setSide,
    idMode,
    setIdMode,
    age,
  } = props;
  const idHandler = Number(idMode) ? setRut : (rutHandler: string) => setRut(rutParser(rutHandler));

  return (
    <>
      <h1>Formulario de registro de imágenes pie diabético</h1>
      <Accordion
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
        title="Información general"
      >
        <TextField
          label="Nombres"
          type="text"
          required
          value={names}
          onChange={(e) => setNames(e.target.value)}
          size="small"
          margin="dense"
        />
        <TextField
          label="Apellidos"
          type="text"
          required
          value={lastNames}
          onChange={(e) => setLastNames(e.target.value)}
          size="small"
          margin="dense"
        />

        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
          <RadioQuestion
            title="Identificación: "
            options={['RUT', 'DNI']}
            onChange={setIdMode}
            required
            value={idMode}
            labelStyle={{ paddingTop: '0.6em', marginRight: '1em' }}
            row
            defaultValue={idMode}
          />
          <div style={{ width: '50%' }}>
            <TextField
              label={Number(idMode) ? 'DNI' : 'RUT'}
              type="text"
              required
              value={rut}
              onChange={(e) => idHandler(e.target.value)}
              fullWidth
              size="small"
              margin="dense"
            />
          </div>
        </Stack>

        <br />
        <Divider flexItem />
        <br />

        <Typography variant="h6" component="div" gutterBottom>
          {age !== 0 && `Edad: ${age}`}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <DatePicker
            label="Fecha de Nacimiento"
            value={birthDate}
            onChange={(newValue: any) => {
              setBirthDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <br />
        <Divider flexItem />

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <DatePicker
            label="Fecha de toma de foto"
            value={registerDate}
            onChange={(newValue: any) => {
              setRegisterDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <br />

        <RadioQuestion
          title="Lado:"
          options={['Izquierdo', 'Derecho']}
          onChange={setSide}
          required
          value={side}
          labelStyle={{ paddingTop: '0.6em', marginRight: '0.5em' }}
          row
        />
      </Accordion>
    </>
  );
};

export default GeneralData;
