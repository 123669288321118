import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes/routes';
import UserProvider from '../components/userContext';
import { Account } from '../common/Account';

function App(): JSX.Element {
  return (
    <UserProvider>
      <Account>
        <Router>
          <Routes />
        </Router>
      </Account>
    </UserProvider>
  );
}

export default App;

// export default withAuthenticator(App);
