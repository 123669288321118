import React, { useState } from 'react';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { poolData } from '../../common/cognito';

type typeloginErrors = {
  [key: string]: string;
};

const recoverPasswordErrors: typeloginErrors = {
  LimitExceededException: 'Ha excedido el límite de intentos',
  CodeMismatchException: 'El código ingresado es incorrecto',
  UserNotFoundException: 'El correo ingresado es incorrecto.',
  InvalidPasswordException: 'La contraseña no cumple con los requisitos',
};

const PasswordRecoveryForm: React.FC<Record<string, never>> = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [requirements, setRequirements] = useState(false);
  const [verifyNewPassword, setVerifyNewPassorwd] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const getUser = () => {
    return new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });
  };
  const steps = [
    'Ingrese su correo electrónico',
    'Crea una nueva contraseña',
    'Finalizado',
  ];
  const sendCode = (e: React.SyntheticEvent) => {
    e.preventDefault();
    getUser().forgotPassword({
      onSuccess: () => {
        setActiveStep(1);
      },
      onFailure: (err) => {
        setError(recoverPasswordErrors[err.name]);
      },
      inputVerificationCode: () => {
        setActiveStep(1);
      },
    });
  };

  const resetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (newPassword === verifyNewPassword) {
      getUser().confirmPassword(code, newPassword, {
        onSuccess: () => {
          setActiveStep(2);
        },
        onFailure: (err) => {
          setError(recoverPasswordErrors[err.name]);
        },
      });
    } else {
      setError('Las contraseñas no coinciden');
    }
  };

  return (
    <>
      <h1 style={{ color: 'white' }}>Recuperar Contraseña</h1>
      <Container
        maxWidth="sm"
        sx={{
          borderRadius: '1em',
          bgcolor: 'white',
          padding: '1em 1em 1em 1em',
          textAlign: 'center',
        }}
      >
        {error && (
          <Alert
            onClose={() => {
              setError('');
            }}
            severity="error"
          >
            {error}
          </Alert>
        )}
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '2em',
          marginTop: '2em',
        }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep === 0 && (
          <form onSubmit={sendCode}>
            <TextField
              label="Ingrese su mail"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
              margin="dense"
            />
            <div>
              <Button type="submit" variant="contained" color="primary">
                Enviar código a mi correo
              </Button>
            </div>
          </form>
        )}
        {activeStep === 1 && (
          <>
            <Button
              disabled={requirements}
              variant="outlined"
              onClick={() => {
                setRequirements(true);
              }}
            >Revisar requisitos de contraseña
            </Button>
            <Collapse in={requirements}>
              <Alert
                severity="info"
                action={(
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setRequirements(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
              )}
              >
                Asegúrese que la contraseña cumpla con los siguientes requisitos.
                <p>Mínimo 6 carácteres.</p>
                <p>Que incluya al menos un número.</p>
                <p>Que incluya al menos una letra mayúscula.</p>
                <p>Que incluya al menos un carácter especial.</p>
              </Alert>
            </Collapse>
            <form onSubmit={resetPassword}>
              <TextField
                label="Ingrese su código"
                type="text"
                required
                value={code}
                onChange={(e) => setCode(e.target.value)}
                size="small"
                margin="dense"
              />
              <TextField
                label="Ingrese su nueva constraseña"
                type="password"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                size="small"
                margin="dense"
              />
              <TextField
                label="Confirmar nueva contraseña"
                type="password"
                required
                value={verifyNewPassword}
                onChange={(e) => setVerifyNewPassorwd(e.target.value)}
                size="small"
                margin="dense"
              />
              <div>
                <Button type="submit" variant="contained" color="primary">
                  Confirmar
                </Button>
              </div>
            </form>
          </>
        )}
        {activeStep === 2 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2em' }}>
            <Alert severity="success">Se ha actualizado tu contraseña correctamente!</Alert>
          </div>
        )}
        <Link sx={{ marginTop: '2em' }} href="/">
          Volver al inicio
        </Link>
      </Container>
    </>
  );
};

export default PasswordRecoveryForm;
