import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import visuallyHidden from '@mui/utils/visuallyHidden';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import { tableEntry, metricsEntry, modelEntry } from '../../common/data-types';

type DataProps = {
  state: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableData: any;
    rawData: tableEntry[];
  }
  dispatch: React.Dispatch<{
      type: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any;
  }>;
};

const getPredictedValue = (modelPredictions: modelEntry) => {
  let predicted = '';
  let confidence = 0;
  Object.entries(modelPredictions).forEach(([key, value]) => {
    if (value > confidence) {
      if (key) {
        predicted = key.toString().toUpperCase();
      }
      confidence = value;
    }
  });
  if (predicted === 'W4ORW5') {
    predicted = 'W4 o W5';
  }
  return { predicted, confidence };
};

function CollapsableImagesRow(props: { row: tableEntry }): JSX.Element {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  // Get the correct coloring for the row
  const { predicted, confidence } = getPredictedValue(row.modelClassification);
  const realClass = row.wagnerClassification.toString();
  let isCorrect = false;
  if (predicted) {
    isCorrect = Array.from(predicted).includes(realClass);
  }

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        style={{ backgroundColor: !isCorrect ? 'rgb(240,190,200)' : 'rgb(190,240,200)' }}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.side}
        </TableCell>
        <TableCell align="center">{row.wagnerClassification}</TableCell>
        <TableCell align="center">{predicted}</TableCell>
        <TableCell align="center">{`${Math.round(confidence)}%`}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, width: '100%' }}>
              <Typography variant="h6" gutterBottom component="div" align="center">
                Resultados modelo:
              </Typography>

              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <Box
                    component="img"
                    sx={{
                      maxWidth: '100%',
                      maxHeight: 550,
                    }}
                    alt="Foto subida por el usuario."
                    src={row.originalUrl}
                  />
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <Box
                    component="img"
                    sx={{
                      maxWidth: '100%',
                      maxHeight: 550,
                    }}
                    alt="Resultados modelo de AI."
                    src={row.gradUrl}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CollapsableDataRow(props: { row: metricsEntry }): JSX.Element {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <strong>{row.batch}</strong>
        </TableCell>
        <TableCell align="center">{row.rut}</TableCell>
        <TableCell align="center">{row.age}</TableCell>
        <TableCell align="center">{row.date}</TableCell>
        <TableCell align="left">{row.user}</TableCell>
        <TableCell align="center">
          <Link
            to={{
              pathname: '/edit',
              state: row,
            }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Resultados
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell />

                    <TableCell>
                      <strong>Lado</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Wagner Real</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Wagner Modelo</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Confidence</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.data.map((rowData: tableEntry) => (
                    <CollapsableImagesRow key={rowData.objectKey} row={rowData} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const descendingComparator = (a: string, b: string, orderBy: any) => {
  // With the commented code it is possible to check if an attribute is a date
  // const isInteger = Number.isInteger(a[orderBy]) && Number.isInteger(b[orderBy]);
  // const isDate = !isInteger && !Number.isNaN(Date.parse(a[orderBy])) && !Number.isNaN(Date.parse(b[orderBy]));
  // const [checkA, checkB] = !isDate ? [a, b] : [Date.parse(a[orderBy]), Date.parse(b[orderBy])];

  let checkA: number | string;
  let checkB: number | string;

  if (orderBy === 'rut') {
    [checkA, checkB] = [
      Number(a[orderBy].split('-')[0].split('.').join('')),
      Number(b[orderBy].split('-')[0].split('.').join('')),
    ];
  } else if (orderBy === 'date') {
    [checkA, checkB] = [
      Date.parse(a[orderBy].replace(/(\d+[/])(\d+[/])/, '$2$1')),
      Date.parse(b[orderBy].replace(/(\d+[/])(\d+[/])/, '$2$1')),
    ];
  } else {
    [checkA, checkB] = [a[orderBy], b[orderBy]];
  }

  if (Number.isNaN(checkA) || Number.isNaN(checkB)) {
    if (Number.isNaN(checkA) && Number.isNaN(checkB)) {
      return 0;
    } if (Number.isNaN(checkA)) {
      return 1;
    }
    return -1;
  }

  if (checkB < checkA) {
    return -1;
  }
  if (checkB > checkA) {
    return 1;
  }
  return 0;
};

const getComparator = (order: string, orderBy: string) => {
  return order === 'desc'
    ? (a: string, b: string) => descendingComparator(a, b, orderBy)
    : (a: string, b: string) => -descendingComparator(a, b, orderBy);
};

const DataTable: React.FC<DataProps> = (props) => {
  const { state, dispatch } = props;
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>(undefined);
  const [orderBy, setOrderBy] = useState(''); // age, creationDate, date, rut, user

  const { tableData } = state;

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (property: string) => {
    const isAsc = (orderBy !== property) || (orderBy === property && order === 'desc');

    const orderSort = isAsc ? 'asc' : 'desc';
    setOrder(orderSort);
    setOrderBy(property);
    tableData.sort(getComparator(orderSort, property));
    dispatch({ type: 'changeData', payload: tableData });
  };

  const headCells = [
    { id: 'rut', label: 'RUN' },
    { id: 'age', label: 'Edad' },
    { id: 'date', label: 'Fecha' },
    { id: 'user', label: 'Encargado' },
  ];

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <strong>Batch</strong>
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell align="center" key={headCell.id}>
                  <strong>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : undefined}
                      onClick={() => handleSort(headCell.id)}
                    >
                      {headCell.label}
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    </TableSortLabel>
                  </strong>
                </TableCell>
              ))}
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((item: metricsEntry) => (
              <CollapsableDataRow key={item.batch} row={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[30, 50, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default DataTable;
