import React, { createContext } from 'react';
import { CognitoUserSession, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import { userPool } from './cognito';

interface resultType {
  [key: string]: string;
}

interface AccountProps {
  children: JSX.Element[] | JSX.Element;
}
interface getSessionType {
  user: CognitoUser | null;
  session: CognitoUserSession | null;
  attr: resultType;
}

interface IContextProps {
  getSession: () => Promise<getSessionType>;
}
type GetSession = () => Promise<getSessionType>;

const AccountContext = createContext({} as IContextProps);

const Account = (props: AccountProps): React.ReactElement => {
  const { children } = props;

  const getSession: GetSession = async () => {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (user) {
        user.getSession(async (err: Error, session: CognitoUserSession | null) => {
          if (err) {
            reject();
          } else {
            const attr: Record<string, string> = await new Promise((resolve2, reject2) => {
              user.getUserAttributes(
                (err2: Error | undefined, attributes: CognitoUserAttribute[] | undefined) => {
                  if (err2) {
                    reject2(err2);
                  } else {
                    const results: resultType = {};
                    if (attributes) {
                      attributes.forEach((attribute: CognitoUserAttribute) => {
                        const { Name, Value } = attribute;
                        results[Name] = Value;
                      });
                    }
                    resolve2(results);
                  }
                },
              );
            });

            resolve({
              user,
              session,
              attr,
            } as getSessionType);
          }
        });
      } else {
        reject();
      }
    });
  };

  return (
    <AccountContext.Provider
      value={{
        getSession,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
