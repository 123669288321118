/* eslint-disable import/prefer-default-export */
import axios, { AxiosResponse } from 'axios';

import { PhotoMetadata } from '../common/data-types';

const uploadPhoto = async (photoFile:File, metadata: PhotoMetadata, token: string, batch:string):
 Promise<AxiosResponse|void> => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`,
    { ...metadata, batch },
    config);

  if (response.status !== 200) {
    return Promise.reject(photoFile.name);
  }
  const uploadUrl = response.data.s3PutObjectUrl;
  const s3Response = await axios.put(uploadUrl, photoFile, { headers: { 'Content-Type': metadata.contentType } });
  if (s3Response.status !== 200) {
    return Promise.reject(photoFile.name);
  }
  return Promise.resolve();
};

export { uploadPhoto };
