import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Accordion from './Accordion';

interface ColorProps {
  skinColor: string;
  setSkinColor: React.Dispatch<React.SetStateAction<string>>;
}

const colorArray = ['#f0debb', '#e1ac80', '#e1ac77', '#ab7c52', '#7a3e24', '#40302b'];
const colorNames = ['light', 'fair', 'medium', 'midBrown', 'darkBrown', 'veryDarkBrown'];

const ColorPicker = (props: ColorProps): JSX.Element => {
  const { skinColor, setSkinColor } = props;

  return (
    <Accordion title="Seleciona el color de piel más similar a la del paciente:">
      <Box sx={{ '& button': { m: 1 } }}>
        <div>
          {colorNames.map((cname, idx) => {
            let disabled = false;
            if (skinColor !== '' && skinColor !== cname) {
              disabled = true;
            }
            return (
              <Button
                key={cname}
                variant="contained"
                size="large"
                style={{
                  backgroundColor: `${disabled ? 'gray' : colorArray[idx]}`,
                  padding: '32px 48px',
                }}
                onClick={() => {
                  setSkinColor(skinColor === cname ? '' : cname);
                }}
                disabled={disabled}
              />
            );
          })}
        </div>
        {skinColor !== '' && <p>(Clickea en el color para cambiar tu opción)</p>}
      </Box>
    </Accordion>
  );
};

export default ColorPicker;
