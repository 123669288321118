import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';

export const poolData = {
  UserPoolId: `${process.env.REACT_APP_POOL_ID}`,
  ClientId: `${process.env.REACT_APP_CLIENT_ID}`,
};
type SetLoginStatus = (user: boolean) => void;

export const onSuccesCallback = (
  result: AmazonCognitoIdentity.CognitoUserSession,
  setLoginStatus: SetLoginStatus,
): void => {
  AWS.config.region = process.env.REACT_APP_REGION;
  const idToken = result.getIdToken().getJwtToken();
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: `${process.env.REACT_APP_POOL_ID}`, // your identity pool id here
    Logins: {
      // Change the key below according to the specific region your user pool is in.
      [`cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_POOL_ID}`]:
        idToken,
    },
  });
  setLoginStatus(true);
};
export const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
