import React, { useState, useEffect, useContext } from 'react';
import { format } from 'date-fns';
import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { uploadPhoto } from '../../api/users';
import { AccountContext } from '../../common/Account';
import { removeAccents } from '../../common/idParser';

import { GeneralDataProps, SanElianProps, TexasProps } from '../../common/form-types';
import { SanElian, TexasUniversityClassification, PhotoMetadata } from '../../common/data-types';

export const formatDate = (date: Date | null): string => {
  let parsedDate = '';
  try {
    if (date) {
      parsedDate = format(date, 'dd/MM/yyyy');
    }
  } catch (error) {
    parsedDate = '';
  }
  return parsedDate;
};

const calcAge = (birthDate: Date | null, evaluationDate: Date | null) => {
  let age = 0;
  try {
    if (birthDate && evaluationDate) {
      age = evaluationDate.getFullYear() - birthDate.getFullYear();
      const m = evaluationDate.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && evaluationDate.getDate() < birthDate.getDate())) {
        age -= 1;
      }
    }
  } catch (error) {
    age = 0;
  }
  return age;
};

interface HookProps {
  generalDataProps: GeneralDataProps;
  wagner: number;
  setWagner: React.Dispatch<React.SetStateAction<number>>;
  sanElianProps: SanElianProps;
  sanElian: SanElian;
  texasProps: TexasProps;
  texasUniversityClassification: TexasUniversityClassification;
  doctorName: string;
  setDoctorName: React.Dispatch<React.SetStateAction<string>>;
  doctorID: string;
  images: FileList | undefined;
  setImages: React.Dispatch<React.SetStateAction<FileList | undefined>>;
  hasInjury: Record<number, boolean>;
  setHasInjury: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  handleSubmit(event: React.FormEvent): Promise<void | AxiosResponse>;
  uploadError: unknown;
  setError: React.Dispatch<React.SetStateAction<unknown | undefined>>;
  message: boolean;
  setMessage: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

export const FormHook = (): HookProps => {
  const [names, setNames] = useState('');
  const [lastNames, setLastNames] = useState('');
  const [idMode, setIdMode] = useState(0); // 0=rut, 1=DNI
  const [rut, setRut] = useState('');
  const [birthDate, setBirthDate] = React.useState<Date | null>(null);
  const [registerDate, setRegisterDate] = React.useState<Date | null>(null);
  const [side, setSide] = useState(0);
  const [wagner, setWagner] = useState(0);
  const [location, setLocation] = useState(0);
  const [topographicAspects, setTopographicAspects] = useState(0);
  const [affectedZones, setAffectedZones] = useState(0);
  const [ischemia, setIschemia] = useState(0);
  const [infection, setInfection] = useState(0);
  const [edema, setEdema] = useState(0);
  const [neuropathy, setNeuropathy] = useState(0);
  const [depth, setDepth] = useState(0);
  const [area, setArea] = useState(0);
  const [woundHealingPhase, setWoundHealingPhase] = useState(0);
  const [texas, setTexas] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [skinColor, setSkinColor] = useState('');
  const [images, setImages] = React.useState<FileList | undefined>();
  const [hasInjury, setHasInjury] = useState<Record<number, boolean>>({});
  const [doctorID, setDoctorID] = useState('');
  const [doctorEmail, setDoctorEmail] = useState('');
  const [token, setToken] = useState('');
  const [uploadError, setError] = React.useState<unknown | undefined>();
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const resetForm = () => {
    setNames('');
    setLastNames('');
    setRut('');
    setBirthDate(null);
    setRegisterDate(null);
    setSide(0);
    setWagner(0);
    setLocation(0);
    setSkinColor('');
    setTopographicAspects(0);
    setAffectedZones(0);
    setIschemia(0);
    setInfection(0);
    setEdema(0);
    setNeuropathy(0);
    setDepth(0);
    setArea(0);
    setWoundHealingPhase(0);
    setTexas('');
    setDoctorName('');
    setImages(undefined);
    setHasInjury({});
  };

  const { getSession } = useContext(AccountContext);
  const setAttrs = async () => {
    const { attr, session } = await getSession();
    setDoctorID(attr['custom:rut']);
    setDoctorEmail(attr.email);
    if (session) {
      setToken(session.getIdToken().getJwtToken());
    }
  };

  useEffect(() => {
    setAttrs();
  }, []);

  const fields = [
    location,
    topographicAspects,
    affectedZones,
    ischemia,
    infection,
    edema,
    neuropathy,
    depth,
    area,
    woundHealingPhase,
  ]
    .slice()
    .map((field) => (field ? Number(field) : 0));
  const retrieveGrade = (sum: number) => {
    if (sum <= 10) {
      return 1;
    }
    if (sum <= 20) {
      return 2;
    }
    return 3;
  };
  const sum = fields.reduce((acc: number, num: number) => acc + num, 0);
  const grade = retrieveGrade(sum);

  const sanElian: SanElian = {
    score: sum,
    degree: grade,
    location,
    topographicAspects,
    affectedZones,
    ischemia,
    infection,
    edema,
    neuropathy,
    depth,
    area,
    woundHealingPhase,
  };

  const texasUniversityClassification: TexasUniversityClassification = {
    stadium: texas[0],
    grade: Number(texas[1]),
  };

  const sides = ['Izquierdo', 'Derecho'];
  const photoMetadata: PhotoMetadata = {
    title: 'string',
    contentType: 'string',
    pacientNames: removeAccents(names),
    pacientSurnames: removeAccents(lastNames),
    pacientRut: rut,
    pacientDateOfBirth: formatDate(birthDate) || '',
    dateRegistrationImages: formatDate(registerDate) || '',
    age: calcAge(birthDate, registerDate),
    side: sides[side],
    wagnerClassification: wagner,
    sanElian,
    texasUniversityClassification,
    createdAt: format(new Date(), 'dd/MM/yyyy'),
    userName: removeAccents(doctorName),
    userRut: doctorID,
    skinColor,
  };
  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    const files = images || new FileList();
    const metadata = {
      ...photoMetadata,
      doctorEmail,
      totalFiles: files.length,
      contentType: files ? files[0].type : '',
    };
    setLoading(true);

    const batch = uuidv4();
    Promise.all(
      Array.from(files).map((image, idx) => {
        let thisPhotoHasInjury = false;
        if (hasInjury) {
          thisPhotoHasInjury = hasInjury[idx];
        }
        const thisPhotoMetadata = { ...metadata, idx, hasInjury: thisPhotoHasInjury };
        return uploadPhoto(image, thisPhotoMetadata, token, batch);
      }),
    )
      .then(() => {
        setMessage(true);
        setLoading(false);
        resetForm();
      })
      .catch(() => {
        setError('Ha ocurrido un error');
        setLoading(false);
      });
  };

  const generalDataProps: GeneralDataProps = {
    names,
    setNames,
    lastNames,
    setLastNames,
    rut,
    setRut,
    birthDate,
    setBirthDate,
    registerDate,
    setRegisterDate,
    side,
    setSide,
    idMode,
    setIdMode,
    skinColor,
    setSkinColor,
    age: calcAge(birthDate, registerDate),
  };
  const sanElianProps: SanElianProps = {
    location,
    setLocation,
    topographicAspects,
    setTopographicAspects,
    affectedZones,
    setAffectedZones,
    ischemia,
    setIschemia,
    infection,
    setInfection,
    edema,
    setEdema,
    neuropathy,
    setNeuropathy,
    depth,
    setDepth,
    area,
    setArea,
    woundHealingPhase,
    setWoundHealingPhase,
    sum,
    grade,
  };
  const texasProps: TexasProps = {
    texas,
    setTexas,
    doctorName: removeAccents(doctorName),
    setDoctorName,
  };
  return {
    generalDataProps,
    wagner,
    setWagner,
    sanElianProps,
    sanElian,
    texasProps,
    texasUniversityClassification,
    doctorName,
    setDoctorName,
    doctorID,
    handleSubmit,
    images,
    setImages,
    hasInjury,
    setHasInjury,
    uploadError,
    setError,
    message,
    setMessage,
    loading,
  };
};

export default FormHook;
