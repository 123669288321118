import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps, Theme } from '@mui/material/styles';

interface AccordionProps {
  title: string;
  children: JSX.Element[] | JSX.Element;
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps<Theme>;
}

const CustomAccordion = ({ title, sx = [], children }:AccordionProps): JSX.Element => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h2>{title}</h2>
      </AccordionSummary>
      <AccordionDetails sx={sx}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
