import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';

interface ObjectStyle {
  [key:string] : string;
}

interface RadioQuestionProps {
  title: string,
  options: string[];
  onChange: React.Dispatch<React.SetStateAction<number>> | ((e:number) => void);
  required: boolean;
  labelStyle?: ObjectStyle;
  formStyle?: ObjectStyle;
  row?: boolean;
  name?: string;
  value: number;
  defaultValue?: number;
  startAt?: number;
}

const defaultProps = {
  formStyle: undefined,
  name: undefined,
  startAt: 0,
  defaultValue: undefined,
  labelStyle: undefined,
  row: false,
};
const RadioQuestion = (props:RadioQuestionProps):JSX.Element => {
  const {
    title, options, onChange,
    required, labelStyle,
    formStyle, row, name, value, defaultValue, startAt,
  } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number((event.target as HTMLInputElement).value));
  };

  return (
    <FormControl component="fieldset" sx={formStyle}>
      <RadioGroup
        row={row}
        aria-label={title}
        name={name}
        onChange={handleChange}
        value={value}
        defaultValue={defaultValue}
      >
        <FormLabel component="legend" sx={labelStyle}>{title}</FormLabel>
        {options.map((option: string, index: number) => (
          <FormControlLabel
            key={option}
            value={index + (startAt || 0)}
            control={<Radio required={required} />}
            label={option}
          />
        )) }
      </RadioGroup>
    </FormControl>
  );
};
RadioQuestion.defaultProps = defaultProps;
export default RadioQuestion;
