/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import { tableEntry } from '../common/data-types';

export interface defaultResultsResponse {
  status: string;
  message: string;
  data: defaultDataResponse
}

interface defaultDataResponse {
  items: Array<tableEntry>;
  lastEvaluatedKey: string | null;
}

const requestResults = async (
  token: string,
  limit: number,
  lastEvaluatedKey:string | null,
  responseHandler: (res:defaultResultsResponse) => void,
):Promise<void> => {
  const requestParams = {
    headers: { Authorization: `Bearer ${token}` },
    params: lastEvaluatedKey ? { limit, lastEvaluatedKey } : { limit },
  };
  try {
    const apiResponse = await axios.get(`${process.env.REACT_APP_API_URL}/stats`, requestParams);

    if (apiResponse.data.lastEvaluatedKey) {
      requestResults(token, limit, apiResponse.data.lastEvaluatedKey, responseHandler);
    }

    responseHandler({
      status: apiResponse.data.lastEvaluatedKey ? 'success' : 'completed',
      message: 'Data correctly loaded!',
      data: apiResponse.data,
    });
  } catch (error) {
    responseHandler({
      status: 'failed',
      message: 'Usuario no autorizado',
      data: { items: [], lastEvaluatedKey: null },
    });
  }
};

interface defaultResponse {
  status: string;
  message: string;
}

const apiAddNewUser = async (email: string, token: string):Promise<defaultResponse> => {
  const requestConfig = { headers: { Authorization: `Bearer ${token}` } };

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/add-new-user`,
      { email },
      requestConfig,
    );
    return {
      status: 'success',
      message: 'El usuario se agregó correctamente a la whitelist!',
    };
  } catch (error) {
    return {
      status: 'failed',
      message: 'Tu usuario no se encuentra autorizado para realizar la operación.',
    };
  }
};

const editEntry = async (data: Array<tableEntry>, token: string): Promise<defaultResponse> => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/edit-entry`, { ...data }, config);
    return {
      status: 'success',
      message: 'Los datos se actualizaron correctamente!',
    };
  } catch (error) {
    return {
      status: 'failed',
      message: 'Ocurrió un error actualizando los datos, intentelo más tarde o contacte al administrador.',
    };
  }
};

const deleteEntry = async (imageIds: Array<string>, token: string): Promise<defaultResponse> => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/delete-entry`,
      { imageKeys: imageIds },
      config);
    return {
      status: 'success',
      message: 'La entrada fue eliminada correctamente!',
    };
  } catch (error) {
    return {
      status: 'failed',
      message: 'Ocurrió un error eliminando los datos, intentelo más tarde o contacte al administrador.',
    };
  }
};

export {
  apiAddNewUser,
  requestResults,
  editEntry,
  deleteEntry,
};
