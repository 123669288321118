import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginPage from '../../components/login/loginPage';
import ProtectedRoute from './protectedRoute';
import MainForm from '../../components/Form/mainForm';
import RegisterForm from '../../components/register/registerForm';
import ForgotPasswordForm from '../../components/forgotPassword';
import ResultsPage from '../../pages/results/page';
import WhiteList from '../../pages/whitelist/page';
import EditEntry from '../../pages/edit/page';
import PassWordRecover from '../../components/passwordRecover/passwordRecoveryPage';

const Routes = (): JSX.Element => (
  <Switch>
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/registro" component={RegisterForm} />
    <Route exact path="/password_recover" component={PassWordRecover} />
    <ProtectedRoute exact path="/resultados" component={ResultsPage} />
    <ProtectedRoute exact path="/new-user" component={WhiteList} />
    <ProtectedRoute exact path="/edit" component={EditEntry} />
    <ProtectedRoute exact path="/contraseña" component={ForgotPasswordForm} />
    <ProtectedRoute path="/" component={MainForm} />
  </Switch>
);

export default Routes;
